<template>

  <b-card-actions action-collapse title="Daftar Konsumen">
    <b-row>
      
      <b-col md="2" sm="4" class="my-1" v-if="allowCreate()">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
          v-if="allowCreate()"
          @click="add()"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          Konsumen
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModaldetail"
      id="modaldetail"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      size="lg"
      title="Detail Konsumen"
    >
      <b-row>
        <b-col md="4" sm="12">
          <strong class="d">Tanggal</strong>
          <p>
            {{ form.tanggal }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Kondisi</strong>
          <p>
            {{ form.kondisi_ket }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Saldo</strong>
          <p>
            Rp {{ formatRupiah(form.saldo) }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Nama Toko</strong>
          <p>
            {{ form.nama_toko }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Nama Owner</strong>
          <p>{{ form.nama_owner }}</p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Bidang Bisnis</strong>
          <p>
            {{ form.bisnis_fokus }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">No. HP Konsumen</strong>
          <p>
            {{ form.no_hp }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d">Alamat</strong>
          <p>
            {{ form.alamat }}
          </p>
        </b-col>

        <b-col md="4" sm="12">
          <strong class="d-block">Jenis Konsumen</strong>
          <p>
            {{ form.type_konsumen ? form.type_konsumen.nama : "-" }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Bangunan</strong>
          <p>
            {{ form.tipe_bangunan }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Kepemilikan Bangunan</strong>
          <p>
            {{ form.kepemilikan_bangunan }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Lama Sewa Bangunan</strong>
          <p>
            {{ form.lama_sewa }}
          </p>
        </b-col>
        <b-col md="4" sm="12">
          <strong class="d-block">Keterangan</strong>
          <p>
            {{ form.keterangan }}
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModal"
      id="modal-"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      title="Form"
    >
      <!-- 
            cancel-variant="secondary"
            cancel-title="Tutup"-->
      <validation-observer ref="formajuankonsumen">
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19" />
          <h6 class="ml-50 mb-2">Data konsumen</h6>
        </div>
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Pilih Sales" label-for="v-karyawan_id" v-if="isSPV">
                <validation-provider
                  #default="{ errors }"
                  name="karyawan_id"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.karyawan_id"
                    :options="karyawan_id"
                    id="v-karyawan_id"
                    name="karyawan_id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- pelanggan_id -->
            <b-col cols="12">
              <b-form-group label="Tipe Konsumen" label-for="v-pelanggan_id">
                <validation-provider
                  #default="{ errors }"
                  name="pelanggan_id"
                  :rules="{ required: true, min: 1 }"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.pelanggan_id"
                    :options="pelanggan_id"
                    id="v-pelanggan_id"
                    name="pelanggan_id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- nama_toko -->
            <b-col cols="12">
              <b-form-group label="Nama Toko" label-for="v-nama_toko">
                <validation-provider
                  #default="{ errors }"
                  name="nama_toko"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.nama_toko"
                    id="v-nama_toko"
                    placeholder="Nama Toko"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- nama_owner -->
            <b-col cols="12">
              <b-form-group label="Nama Owner" label-for="v-nama_owner">
                <validation-provider
                  #default="{ errors }"
                  name="nama_owner"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.nama_owner"
                    id="v-nama_owner"
                    placeholder="Nama Owner"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- alamat -->
            <b-col cols="12">
              <b-form-group label="Alamat" label-for="v-alamat">
                <validation-provider
                  #default="{ errors }"
                  name="alamat"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    v-model="form.alamat"
                    id="v-alamat"
                    placeholder="Alamat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- no_hp -->
            <b-col cols="12">
              <b-form-group label="Nomor Hp/Telepon" label-for="v-no_hp">
                <validation-provider
                  #default="{ errors }"
                  name="no_hp"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.no_hp"
                    id="v-no_hp"
                    placeholder="no_hp"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- bisnis_fokus -->
            <b-col cols="12">
              <b-form-group label="Binis Toko" label-for="v-bisnis_fokus">
                <validation-provider
                  #default="{ errors }"
                  name="bisnis_fokus"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.bisnis_fokus"
                    id="v-bisnis_fokus"
                    placeholder="Sembako/Restoran/Lain-lain"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- tipe_bangunan -->
            <b-col cols="12">
              <b-form-group label="Tipe Bangunan" label-for="v-tipe_bangunan">
                <validation-provider
                  #default="{ errors }"
                  name="tipe_bangunan"
                  :rules="{ required: true, min: 2 }"
                >
                  <!-- <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.tipe_bangunan"
                        id="v-tipe_bangunan"
                        placeholder="tipe_bangunan"
                      /> -->
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.tipe_bangunan"
                    :options="tipe_bangunan"
                    id="v-tipe_bangunan"
                    name="tipe_bangunan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- kepemilikan_bangunan -->
            <b-col cols="12">
              <b-form-group
                label="Kepemilikan Bangunan"
                label-for="v-kepemilikan_bangunan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="kepemilikan_bangunan"
                  :rules="{ required: true, min: 2 }"
                >
                  <!-- <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.kepemilikan_bangunan"
                        id="v-kepemilikan_bangunan"
                        placeholder="kepemilikan_bangunan"
                      /> -->
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.kepemilikan_bangunan"
                    :options="kepemilikan_bangunan"
                    id="v-kepemilikan_bangunan"
                    name="kepemilikan_bangunan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- lama_sewa -->
            <b-col cols="12">
              <b-form-group label="Lama Sewa" label-for="v-lama_sewa">
                <validation-provider
                  #default="{ errors }"
                  name="lama_sewa"
                  :rules="{ required: true, min: 1 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.lama_sewa"
                    id="v-lama_sewa"
                    placeholder="Lama Sewa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Keterangan -->
            <b-col cols="12">
              <b-form-group label="Keterangan" label-for="v-keterangan">
                <validation-provider
                  #default="{ errors }"
                  name="keterangan"
                  rules="required|min:2"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    v-model="form.keterangan"
                    id="v-keterangan"
                    placeholder="Isi Keterangan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                @click.prevent="submit"
                variant="primary"
                class="mr-1"
              >
                Konsumen
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>

          <template #cell(status)="{ item }">
            <b-badge
              v-if="getStatusName(item.status)"
              :variant="getStatusName(item.status).warna"
            >
              {{ getStatusName(item.status).nama_status }}
            </b-badge>
            <span v-else>
              {{ getStatusName(item.status) }}
            </span>
          </template>

          <template #cell(saldo)="{item}">
            Rp {{ formatRupiah(item.saldo) }}
          </template>

          <template #cell(actions)="row">
            <b-button
              v-b-tooltip.hover.right="'Detail'"
              size="sm"
              @click="detail(row.item)"
              class="mr-1"
              variant="outline-success"
            >
              <!-- @click.prevent="$router.push(`/konsumen/${row.item.id}`)" -->
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.right="'Ubah'"
              v-if="allowUpdate()"
              size="sm"
              @click="edit(row.item)"
              class="mr-1"
              variant="outline-info"
            >
              <!-- @click="info(row.item, row.index, $event.target)" -->
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="allowDelete()"
              v-b-tooltip.hover.right="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only>
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedStatus: false,
      showModal: false,
      showModaldetail: false,
      required,
      password,
      email,
      confirmed,
      form: {
        karyawan_id: "",
        pelanggan_id: "",
        nama_toko: "",
        nama_owner: "",
        no_hp: "",
        bisnis_fokus: "",
        tipe_bangunan: "",
        kepemilikan_bangunan: "",
        lama_sewa: "", //Di isi ketika kepemilikan bangunan status sewa
        alamat: "",
        status: null,
        keterangan: "",
        tanggal: new Date(),
        kondisi_ket: "",
      },
      tipe_bangunan: [
        { value: "Permanen", text: "Permanen" },
        { value: "Non Permanen", text: "Non Permanen" },
      ],
      kepemilikan_bangunan: [
        { value: "Milik sendiri", text: "Milik sendiri" },
        { value: "Sewa", text: "Sewa" },
      ],
      pelanggan_id: [],
      karyawan_id: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      statusOptions: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      statusKonsumenOptions: []
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async created() {
    // set fields
    this.setFields()
    const currentMenu = await this.currentMenu('/ajuankonsumen')
    if(currentMenu) {
      this.getStatusKonsumen(currentMenu.id)
    }
  },
  async mounted() {
    this.currMenu = await this.currentMenu();
    this.getStatus();
    this.getkaryawan_id();
    this.getData();
    this.getpelanggan_id();
    if (this.isSales) {
      this.form.karyawan_id = this.user.karyawan.id;
    }
  },
  methods: {
    async getStatusKonsumen(menu_id) {
      const statusOptions = await this.$store.dispatch("statusrole/getData", {
        menu_id,
      });
      const validStatus = []

      statusOptions.map(status => {
        const find = validStatus.find(v_status => v_status.data_status == status.data_status)

        if(!find) {
          validStatus.push({
            value: status.id,
            text: status.nama_status
          })
        }
      })

      this.statusKonsumenOptions = validStatus
    },
    setFields() {
      if(this.isSales) {
        this.fields = [
          {
            key: "no",
            label: "no",
          },
          { key: "nama_owner", label: "Nama Konsumen", sortable: true },
          { key: "nama_toko", label: "Nama Toko", sortable: true },
          { key: "type_konsumen.nama", label: "Tipe Konsumen", sortable: true },
          { key: "no_hp", label: "HP", sortable: true },
          { key: "saldo", label: "Saldo", sortable: true },
          { key: "actions", label: "Aksi" }
        ]
      }
      else {
        this.fields = [
          {
            key: "no",
            label: "no",
          },
          { key: "tanggal", label: "tanggal", sortable: true },
          { key: "karyawan.nama_lengkap", label: "sales", sortable: true },
          { key: "nama_toko", label: "Toko", sortable: true },
          { key: "type_konsumen.nama", label: "Tipe", sortable: true },
          { key: "no_hp", label: "HP", sortable: true },
          { key: "saldo", label: "Saldo", sortable: true },
          { key: "actions", label: "Aksi" }
        ]
      }
    },
    getStatusName(data_status) {
      const status = this.statusOptions.find((st) => st.data_status == data_status);
      return status ? status : null;
    },
    async getStatus() {
      this.statusOptions = await this.$store.dispatch("statusrole/getData", {
        menu_id: this.currMenu.id,
      });
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    detail(item) {
      this.form = item;
      if (this.form.type_konsumen) this.form.pelanggan_id = this.form.type_konsumen.id;
      this.id = item.id;
      this.showModaldetail = true;
    },
    edit(item) {
      this.form = item;
      if (this.form.type_konsumen) this.form.pelanggan_id = this.form.type_konsumen.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;

      if (this.isSales) {
        this.form.karyawan_id = this.user.karyawan.id;
      }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data konsumen ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ajuankonsumen/save", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Data Konsumen berhasil dihapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        pelanggan_id: "",
        nama_toko: "",
        nama_owner: "",
        no_hp: "",
        bisnis_fokus: "",
        tipe_bangunan: "",
        kepemilikan_bangunan: "",
        lama_sewa: "", //Di isi ketika kepemilikan bangunan status sewa
        alamat: "",
        status: null,
        keterangan: "",
      };
    },
    submit() {
      this.$refs.formajuankonsumen.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          if (!this.form.status) {
            const pendingKey = "pending";
            const status = this.statusOptions.find(
              (st) => st.nama_status.toLowerCase() == pendingKey
            );
            this.form.status = status ? status.id : null;
          }
          const payload = this.form;
          const initStatus = this.statusOptions.find(
            (st) => st.nama_status.toLowerCase() == "pending"
          );
          if (!initStatus) {
            this.displayError({
              message: "Harap hubungi admin untuk membuat status di aktor sales",
            });
            return false;
          }
          payload.status = initStatus.id;
          this.$store
            .dispatch("ajuankonsumen/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.showModal = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getData() {

      const params = {status: 3}
      if(this.isSales) {
        params.karyawan_id = this.user.karyawan.id
      }

      this.$store.dispatch("ajuankonsumen/getData", params).then(() => {
        this.items = this.$store.state.ajuankonsumen.datas;
        this.totalRows = this.items.length;
      })
      .catch(e => {
        this.displayError(e)
        return false
      })
    },
    getpelanggan_id() {
      this.$store
        .dispatch("tipekonsumen/getData", {})
        .then(() => {
          let tipekonsumen = JSON.parse(
            JSON.stringify(this.$store.state.tipekonsumen.datas)
          );
          tipekonsumen.map((item) => {
            item.value = item.id;
            item.text = item.nama;
          });

          this.pelanggan_id = tipekonsumen;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getkaryawan_id() {
      this.$store
        .dispatch("karyawan/getData", { jabatan_id: 8 })
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.karyawan_id = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    gettipeName(data) {
      let find = this.pelanggan_id.find((item) => item.id == data.status.id);
      return find ? find.nama : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
